import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from '../SearchTopTimes.module.css';

const LargeGridRow = ({ time }) => (
  <tr>
    <td className={style.GridRowNumber}>{time.eventRank || ''}</td>
    <td>{time.eventCode || ''}</td>
    <td>{time.swimTimeFormatted || ''}</td>
    <td>{time.fullName || ''}</td>
    <td>{time.teamName || ''}</td>
    <td className={style.GridRowNumber}>{time.swimmerAge || ''}</td>
    <td>{time.meetName || ''}</td>
    <td>{time.swimDate ? formatDate(time.swimDate) : ''}</td>
    <td>{time.timeStandardName || ''}</td>
  </tr>
);

const TopTimesSearchGridLarge = ({ isLoading, gridData }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Rank'} columnField={'eventRank'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '120px' }} sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Time'} columnField={'swimTimeFormatted'}
            sortType={SGConstants.SORT_TYPE_TIME} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'fullName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Team'} columnField={'teamName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age'} columnField={'swimmerAge'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Date'} columnField={'swimDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '120px' }} sortableGridState={sortableGridState} columnLabel={'Time Standard'} columnField={'timeStandardName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="9">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((time, i) =>
              <LargeGridRow
                key={i}
                time={time} />)
            : <tr><td colSpan="9">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default TopTimesSearchGridLarge;