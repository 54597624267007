import { Fragment } from "react";

import useTopTimesSearchForm from "./UseTopTimesSearchForm";

import EventDwMultiSelect from "../multiselects/EventDwMultiSelect";
import TimeStandardsDwDropdown from "../dropdowns/TimeStandardsDwDropdown";
import ReportPeriodSeasonDwDropdown from "../dropdowns/ReportPeriodSeasonDwDropdown";
import EventCompetitionGenderDwDropdown from "../dropdowns/EventCompetitionGenderDwDropdown";

import Input from "../../../../common/components/inputs/Input";
// import Spinner from "../../../../common/components/spinners/Spinner";
import ReadOnly from "../../../../common/components/readOnly/ReadOnly";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import DatePicker from "../../../../common/components/datepickers/DatePicker";
import LscDropdown from "../../../../common/components/dropdowns/lscDropdown/LscDropdown";
import YesNoSwitch from "../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import ApprovedClubCombobox from "../../../../common/components/comboboxes/approvedClubCombobox/ApprovedClubCombobox";
import ReportListAsButton from "../../../../common/components/embeddedReports/ReportListAsButton";
import ZoneDropdown from "../../../../common/components/dropdowns/zoneDropdown/ZoneDropdown";

import global from '../../../../common/components/GlobalStyle.module.css';

const TopTimesSearchForm = ({ onSubmitFormCallback, canDownloadReport, routeName, lscOrgUnitObj, clubOrgUnitObj }) => {
  const {
    organizationCode,
    displayOptions,
    // isLoading,
    reportParametersState,
    formState,
    errorState,
    updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onZoneOrgUnitChanged,
    onLscOrgUnitChanged,
    onClubOrgUnitChanged,
    onDisplayOptionChanged,
    onClearFormClicked,
  } = useTopTimesSearchForm(onSubmitFormCallback, lscOrgUnitObj, clubOrgUnitObj);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {(!lscOrgUnitObj && !clubOrgUnitObj) && // only display if no lsc or club are provided
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ZoneDropdown
              label="Zone"
              name="zoneOrgUnitId"
              value={formState.zoneOrgUnitId}
              error={errorState.zoneOrgUnitId}
              message={errorState.zoneOrgUnitId}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onZoneOrgUnitChanged(newValue, newValueLabel)
              }} />
          </div>
        }
        {(clubOrgUnitObj === undefined || clubOrgUnitObj === null) && // if club is provided, hide lsc
          <div className="col-xs-12 col-sm-6 col-md-4">
            {lscOrgUnitObj
              ? (
                <ReadOnly
                  label='LSC'
                  name='lscOrgUnitId'
                  value={lscOrgUnitObj.orgUnitName} />
              ) : (
                <LscDropdown
                  label="LSC"
                  name="lscOrgUnitId"
                  value={formState.lscOrgUnitId}
                  error={errorState.lscOrgUnitId}
                  message={errorState.lscOrgUnitId}
                  onChange={(newValue, newValueLabel, e) => {
                    e && e.target && e.target.value && onLscOrgUnitChanged(newValue, newValueLabel)
                  }} />
              )
            }
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          {clubOrgUnitObj
            ? (
              <ReadOnly
                label='Club'
                name='clubOrgUnitId'
                value={clubOrgUnitObj.orgUnitName} />
            ) : (
              <ApprovedClubCombobox
                label={formState.clubInputLabel}
                name="clubOrgUnitName"
                error={errorState.clubOrgUnitId}
                message={errorState.clubOrgUnitId}
                valueToMatch={formState.clubOrgUnitName}
                lscId={lscOrgUnitObj?.orgUnitId || formState.lscOrgUnitId}
                onChange={(newValue, newValueLabel) => {
                  onClubOrgUnitChanged(newValue, newValueLabel);
                }} />
            )
          }
        </div>
        {organizationCode &&
          <Fragment>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReportPeriodSeasonDwDropdown
                label="Season"
                name="reportPeriodId"
                value={formState.reportPeriodId}
                error={errorState.reportPeriodId}
                message={errorState.reportPeriodId}
                organizationCode={organizationCode}
                onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'reportPeriodId', valueLabel, 'reportPeriodName') }} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <EventDwMultiSelect
                label="Event(s)"
                name="events"
                value={formState.events}
                error={errorState.events}
                message={errorState.events}
                organizationCode={organizationCode}
                onChange={(value) => { updateFormState('events', value) }} />
            </div>
          </Fragment>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Start Date"
            name="startDate"
            value={formState.startDate}
            error={errorState.startDate}
            message={errorState.startDate}
            onChange={(value) => { updateFormState('startDate', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="End Date"
            name="endDate"
            value={formState.endDate}
            error={errorState.endDate}
            message={errorState.endDate}
            onChange={(value) => { updateFormState('endDate', value) }} />
        </div>
        <div className="col-xs-6 col-sm-3 col-md-2">
          <Input
            label="Min Age"
            name="minAge"
            type="number"
            min="1"
            max="99"
            value={formState.minAge}
            error={errorState.minAge}
            message={errorState.minAge}
            onChange={(value) => { updateFormState('minAge', value) }} />
        </div>
        <div className="col-xs-6 col-sm-3 col-md-2">
          <Input
            label="Max Age"
            name="maxAge"
            type="number"
            min="1"
            max="99"
            value={formState.maxAge}
            error={errorState.maxAge}
            message={errorState.maxAge}
            onChange={(value) => { updateFormState('maxAge', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <EventCompetitionGenderDwDropdown
            label="Event Gender"
            name="eventCompetitionGenderTypeId"
            value={formState.eventCompetitionGenderTypeId}
            error={errorState.eventCompetitionGenderTypeId}
            message={errorState.eventCompetitionGenderTypeId}
            onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'eventCompetitionGenderTypeId', valueLabel, 'eventCompetitionGenderTypeName') }} />
        </div>
        {organizationCode &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeStandardsDwDropdown
              label="Time Standard"
              name="timeStandardId"
              value={formState.timeStandardId}
              error={errorState.timeStandardId}
              message={errorState.timeStandardId}
              organizationCode={organizationCode}
              onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'timeStandardId', valueLabel, 'timeStandardName') }} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label="Limit by US Citizen/Eligible to Represent Team USA"
            name="isUsCitizen"
            checked={formState.isUsCitizen}
            error={errorState.isUsCitizen}
            message={errorState.isUsCitizen}
            onChange={(value) => { updateFormState('isUsCitizen', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label='Display Options'
            name='displayOptionId'
            value={formState.displayOptionId}
            error={errorState.displayOptionId}
            message={errorState.displayOptionId}
            options={displayOptions}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value && onDisplayOptionChanged(newValue);
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Max Results"
            name="maxResults"
            type="number"
            min="1"
            max="200"
            value={formState.maxResults}
            error={errorState.maxResults}
            message={errorState.maxResults}
            onChange={(value) => { updateFormState('maxResults', value) }} />
        </div>
      </div>
      <div className="row">
        {errorState.hasRequiredField &&
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.hasRequiredField}</p>
          </div>
        }
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for Times</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>&nbsp;
          {canDownloadReport === true && reportParametersState !== null &&
            <ReportListAsButton
              routeName={routeName}
              reportParameters={reportParametersState} />
          }
        </div>
      </div>
    </form>
  );
};

export default TopTimesSearchForm;